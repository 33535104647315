







































































































































































































































































































































































































































































































































































#new-broadcast {
  .flatpickr-input {
    background: #FFF !important;
  }
}

.broadcasts-page {
  tr.draft {
    background-color: #eaeaea;
  }
  .email-td-class {
    text-align: center;
    vertical-align: middle;
    font-size: 17px;
    span.sub{
      font-size: 14px;
    }
  }
  .opened_field {
    cursor: pointer;
  }
}

.new-broadcast-type {
  .btn {
    width: 100px;
    height: 100px;
    border-width: 2px;
  }
}
